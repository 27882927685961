<template>
  <b-row>
    <b-col cols="12" lg="6">
      <b-card no-header>
        <template slot="header">
          Card Connect Settings
        </template>
        <b-form-group label="MID" label-for="mid" description="Enter the cardconnect MID">
          <b-input-group>
            <b-form-input id="mid" type="text" v-model="cardConnect.mid"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="API Domain" label-for="apiDomain" description="e.g. your.domain.com">
          <b-input-group>
            <b-form-input id="apiDomain" type="text" v-model="cardConnect.domain"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Username" label-for="username" description="Enter the cardconnect username">
          <b-input-group>
            <b-form-input id="username" type="text" v-model="cardConnect.username"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Password" label-for="password" description="">
          <b-input-group>
            <b-form-input id="password" type="text" v-model="cardConnect.password"></b-form-input>
          </b-input-group>
        </b-form-group>
        <template slot="footer">
          <b-row>
            <b-col sm="12" class="text-right">
              <b-button variant="success" @click="save">Save</b-button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'CardConnect',
  data: () => {
    return {
      cardConnect: {
        mid: null,
        domain: null,
        username: null,
        password: null
      }
    }
  },
  methods: {
    fetchConfig () {
      this.$api.configurations.get('cardConnect')
        .then((response) => {
          let config = response.data.cardConnect || {}
          this.cardConnect.mid = config.mid || null
          this.cardConnect.domain = config.domain || null
          this.cardConnect.username = config.username || null
          this.cardConnect.password = config.password || null
        })
        .catch((err) => {
          this.$snotify.error('Error fetching configuration')
        })
    },
    save () {
      this.$api.configurations.bulkUpdate({ cardConnect: { ...this.cardConnect } })
        .then((response) => {
          this.$snotify.success('Card Connect Configuration Saved')
        })
        .catch((err) => {
          this.$snotify.error('Error Saving Configuration')
        })
    }
  },
  created () {
    this.fetchConfig()
  }
}
</script>
